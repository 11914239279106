import { useCallback, useState, useEffect, FC } from 'react'

import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline'
import CircularProgress from '@mui/material/CircularProgress'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'

import { toast } from '@redwoodjs/web/toast'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { useAuth } from 'src/Providers'

import Button from '../Library/Button/Button'

import {
  Icon,
  IconSelectorProps,
  IconSelectorGridProps,
  IconUploadPreviewProps,
} from './IconSelectorHelper'

/**
 * Icon Selector
 * @description Users can select from a provided list of icons, or upload their own. On change/select will trigger a provided callback function that uses the selected icon object
 */
const IconSelector: FC<IconSelectorProps> = ({
  selectableIcons,
  onIconSelect,
  setUploadingStatus,
  currentIcon,
  isGlobalIcon = false,
}) => {
  // STATE MANAGEMENT
  const [iconUrl, setIconUrl] = useState<string>('')
  const [activeIcon, setActiveIcon] = useState<Icon | null>(null)

  const { currentUser } = useAuth()
  const clientId = currentUser.membershipData.clientId

  // UPLOAD FUNCTION
  const [uploadIcon, { result, error, loading: itemIconUploading }] =
    useUploadStorageObject({
      prefixes: [`${clientId}`, 'informer', 'technology'],
      fileName: 'icon',
      isGlobal: isGlobalIcon,
      allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
    })

  // UPLOAD ACTION
  const uploadIconHandler = useCallback(
    (event) => {
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.')
      }

      const file: File = event.target.files[0]
      uploadIcon({ file })
    },
    [uploadIcon],
  )

  // UPLOAD SUCCESS
  useEffect(() => {
    result && setIconUrl(result.downloadUrl)
    result && onIconSelect(result)
  }, [result])

  // UPLOAD STATUS
  useEffect(() => {
    setUploadingStatus && setUploadingStatus(itemIconUploading)
  }, [itemIconUploading])

  // UPLOAD ERROR
  useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  // SET CURRENT ICON AS PREVIEW
  useEffect(() => {
    if (currentIcon) {
      setIconUrl(currentIcon?.downloadUrl)
    }
  }, [currentIcon])

  // SET ACTIVE ICON AS PREVIEW
  useEffect(() => {
    if (activeIcon?.id) {
      setIconUrl(activeIcon?.downloadUrl)
      onIconSelect(activeIcon)
    }
  }, [activeIcon])

  // ONCLICK REMOVE ICON
  function removeIcon() {
    setIconUrl('')
    setActiveIcon(null)
    onIconSelect({})
  }

  return (
    <div>
      {!iconUrl && (
        <>
          <p className="text-sm text-gray-400 mb-2">Icon Library</p>
          <IconSelectorGrid
            icons={selectableIcons}
            setActiveIcon={setActiveIcon}
          />
        </>
      )}
      {iconUrl ? (
        <IconFound iconUrl={iconUrl} removeIcon={removeIcon} />
      ) : (
        <IconUpload
          itemIconUploading={itemIconUploading}
          uploadIconHandler={uploadIconHandler}
        />
      )}
    </div>
  )
}

/**
 * Icon Upload Preview
 * @description Dark and Light Preview for uploaded icons
 * @param theme Provide "Light" or "Dark" to set preview type
 * @param iconUrl Provide download url for icon preview
 */
const IconUploadPreview: FC<IconUploadPreviewProps> = ({
  theme = 'Light',
  iconUrl,
}) => {
  return (
    <div className="w-1/2">
      <p className="max-w-2xl text-sm text-gray-500 mb-2">
        {theme} background preview
      </p>
      <img
        alt={`${theme} icon`}
        className={`h-32 p-4 rounded-lg object-contain ${
          theme === 'Light' ? 'bg-gray-200' : 'bg-gray-800'
        }`}
        src={iconUrl}
      />
    </div>
  )
}

/**
 * Icon Selector Grid
 * @description Grid of selectable icons, use the callback function to save selection
 * @param icons Provide an array of icons with the objectStorageId and downloadUrl
 * @param setActiveIcon SetStateAction function to set activeIcon
 */
const IconSelectorGrid: FC<IconSelectorGridProps> = ({
  icons,
  setActiveIcon,
}) => {
  const emptyIconList = !icons.length

  return (
    <div
      id="defaultIconGrid"
      className={`px-2 rounded bg-gray-100 min-h-52 max-h-52 overflow-y-scroll mb-4 border-y-8 border-gray-100 flex ${
        emptyIconList && 'items-center justify-center'
      }`}
    >
      {emptyIconList ? (
        <span className="text-sm text-gray-400 w-full grow text-center select-none">
          No icons found in library
        </span>
      ) : (
        <div
          className="grid gap-2 w-full"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(45px, 1fr))',
            gridTemplateRows: 'repeat(auto-fill, minmax(35px,auto))',
          }}
        >
          {icons.map((icon, index) => (
            <Button
              variant="text"
              className="p-2 text-gray-600 rounded flex items-center justify-center min-w-0"
              key={index}
              onClick={(e) => {
                e.preventDefault()
                setActiveIcon({ id: icon?.id, downloadUrl: icon?.downloadUrl })
              }}
            >
              <img
                src={icon?.downloadUrl}
                alt=""
                className="h-8 w-8 object-contain"
              />
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

/**
 * Icon Upload
 * @description File Input Tag with loading state
 * @param {boolean} itemIconUploading true/false icon is being uploaded to storage
 * @param {customHook} uploadIconHandle custom hook to upload icon on selection
 */

const IconUpload = ({ itemIconUploading, uploadIconHandler }) => {
  return (
    <label className="bg-white border border-indigo-600 gap-2 flex items-center justify-center py-3 px-4 w-full rounded text-sm text-indigo-600 text-center font-medium hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:bg-gray-100 focus:ring-indigo-500 cursor-pointer">
      {itemIconUploading ? (
        <CircularProgress size={20} className="text-indigo-500" />
      ) : (
        <>
          <ArrowUpTrayIcon className="w-4 h-4" />
          Upload File
        </>
      )}
      <input
        type="file"
        id="fileIconCustomUpload"
        name="icon"
        accept="image/*, video/*"
        disabled={itemIconUploading}
        onChange={(e) => uploadIconHandler(e)}
        className="hidden"
      />
    </label>
  )
}

/**
 * Icon Found
 * @description Preview provided Icon and give option to unassign
 * @param {string} iconUrl Provide download url for icon preview
 * @param {function} removeIcon custom function with actions to clear file input and selected icon object
 */

const IconFound = ({ iconUrl, removeIcon }) => {
  return (
    <div className="bg-gray-100 p-4 rounded">
      <div className="flex gap-4 justify-between items-center mb-4">
        <span className="font-bold text-sm">Current Icon</span>
        <Button
          variant="text"
          fullWidth={false}
          className=" text-sm font-medium text-red-500"
          onClick={(e) => {
            e.preventDefault()
            removeIcon()
          }}
          startIcon={<TrashIcon className="w-5 h-5 text-red-500" />}
        >
          Remove
        </Button>
      </div>
      <div className="flex flex-row gap-2">
        <IconUploadPreview theme="Light" iconUrl={iconUrl} />
        <IconUploadPreview theme="Dark" iconUrl={iconUrl} />
      </div>
    </div>
  )
}

export default IconSelector
