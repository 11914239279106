export const CREATE_SECTION = gql`
  mutation CreateInformerSection($input: CreateInformerSectionInput!) {
    createInformerSection(input: $input) {
      id
      name
      clientId
    }
  }
`

export const DELETE_SECTION = gql`
  mutation DeleteInformerSection($id: Int!) {
    deleteInformerSection(id: $id) {
      id
    }
  }
`

export const UPDATE_SECTION = gql`
  mutation UpdateInformerSectionMutation(
    $input: UpdateInformerSectionInput!
    $id: Int!
  ) {
    updateInformerSection(input: $input, id: $id) {
      id
      name
    }
  }
`

export const UPDATE_SECTIONS = gql`
  mutation UpdateInformerSectionsMutation(
    $ids: [Int!]!
    $inputArray: [UpdateInformerSectionInput!]!
  ) {
    updateInformerSections(inputArray: $inputArray, ids: $ids) {
      name
      order
    }
  }
`
export const CREATE_SECTION_TO_GROUP = gql`
  mutation CreateInformerSectionToMembershipGroupMutation(
    $input: CreateInformerSectionToMembershipGroupInput!
  ) {
    createInformerSectionToMembershipGroup(input: $input) {
      informerSectionId
      membershipGroupId
    }
  }
`

export const DELETE_SECTION_TO_GROUP = gql`
  mutation DeleteInformerSectionToMembershipGroupMutation($id: Int!) {
    deleteInformerSectionToMembershipGroup(id: $id) {
      id
    }
  }
`

export const CREATE_CARD_ITEM = gql`
  mutation CreateInformerListCardMutation(
    $input: CreateInformerListCardInput!
  ) {
    createInformerListCard(input: $input) {
      id
    }
  }
`
export const CREATE_CARD_TEMPLATE_ITEM = gql`
  mutation CreateInformerListCardTemplateMutation(
    $input: CreateInformerListCardInput!
  ) {
    createInformerListCardTemplate(input: $input) {
      id
    }
  }
`
export const CREATE_CARD_ITEMS = gql`
  mutation CreateManyInformerListCardMutation(
    $input: [CreateInformerListCardInput!]!
  ) {
    createManyInformerListCards(input: $input) {
      name
    }
  }
`

export const CREATE_CARD_TO_GROUP = gql`
  mutation CreateInformerListCardToMembershipGroupMutation(
    $input: CreateInformerListCardToMembershipGroupInput!
  ) {
    createInformerListCardToMembershipGroup(input: $input) {
      informerListCardId
      membershipGroupId
    }
  }
`

export const CREATE_CARD_TO_MEMBERSHIP = gql`
  mutation CreateInformerListCardToMembershipMutation(
    $input: CreateInformerListCardToMembershipInput!
  ) {
    createInformerListCardToMembership(input: $input) {
      informerListCardId
      membershipId
    }
  }
`

export const DELETE_CARD_ITEM = gql`
  mutation DeleteInformerListCard($id: Int!) {
    deleteInformerListCard(id: $id) {
      id
    }
  }
`

export const DELETE_CARD_TEMPLATE_ITEM = gql`
  mutation DeleteInformerListCardTemplate($id: Int!) {
    deleteInformerListCardTemplate(id: $id) {
      id
    }
  }
`

export const DELETE_CARD_TO_GROUP = gql`
  mutation DeleteInformerListCardToMembershipGroupMutation($id: Int!) {
    deleteInformerListCardToMembershipGroup(id: $id) {
      id
    }
  }
`

export const DELETE_CARD_TO_MEMBERSHIP = gql`
  mutation DeleteInformerListCardToMembershipMutation($id: Int!) {
    deleteInformerListCardToMembership(id: $id) {
      id
    }
  }
`

export const UPDATE_CARD_ITEM = gql`
  mutation UpdateInformerListCardMutation(
    $input: UpdateInformerListCardInput!
    $id: Int!
  ) {
    updateInformerListCard(input: $input, id: $id) {
      id
    }
  }
`

export const UPDATE_CARD_TEMPLATE_ITEM = gql`
  mutation UpdateInformerListCardTemplateMutation(
    $input: UpdateInformerListCardInput!
    $id: Int!
  ) {
    updateInformerListCardTemplate(input: $input, id: $id) {
      id
    }
  }
`

export const UPDATE_CARD_ITEMS = gql`
  mutation UpdateInformerListCardsMutation(
    $ids: [Int!]!
    $inputArray: [UpdateInformerListCardInput!]!
  ) {
    updateInformerListCards(inputArray: $inputArray, ids: $ids) {
      name
      order
    }
  }
`
